* {
  box-sizing: border-box;
}
svg,
img,
video {
  vertical-align: middle;
}

body {
  margin: 0;
  font-family: "Hanken Grotesk", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:focus {
  outline: none;
}

.tablecell-h {
  min-width: 170 !important;
  background-color: #e5edfb !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.tablecell-h-first {
  min-width: 170 !important;
  background-color: #e5edfb !important;
  border-radius: 8px 0px 0px 0px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.tablecell-h-last {
  min-width: 170 !important;
  background-color: #e5edfb !important;
  border-radius: 0px 8px 0px 0px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.tablecell {
  color: #495057 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;

  /* identical to box height, or 150% */
  text-align: center !important;
}
.rv-xy-plot {
  position: relative;
}
.rv-discrete-color-legend-item__color {
  height: 12px;
  line-height: 12px;
  vertical-align: middle;
  margin-bottom: -10px;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.imgcontainer {
  position: relative;
}
.imgcontainer img {
  display: block;
}
.imgcontainer .MuiSvgIcon-root {
  position: absolute;
  top: 0;
  right: 0;
}
.path {
  display: grid;
  font-size: 1.25rem !important;
}
@media screen and (max-width: 1024px) {
  .path {
    position: absolute !important;
    left: 7rem !important;
    top: 15rem !important;
    transform: translate(-50%, -50%) !important;
    white-space: nowrap !important;
  }
}
#competitor-select,
#workspace-select {
  display: flex;
  align-items: center;
}
.acc-img {
  width: 34px;
  height: 34px;
}
.upload-img {
  width: 100%;
  height: 100%;
}
.icon-6 {
  width: 18px !important;
  height: auto;
}

.rv-discrete-color-legend-item {
  display: flex;
  align-items: center;
}
.blur-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  /* z-index: 100; */
  filter: blur(3px) opacity(0.7);
  pointer-events: none;
}
.path {
  /* display: flex;
justify-content: end;
position: relative;
left: 40rem;
bottom: 15rem; */
  width: min-content;
  word-wrap: break-word;
  font-weight: 400;
  font-size: 16px;
  color: #296bd6;
}
.custom-table-header {
  min-width: 145px;
  background-color: #e5edfb !important;
  font-weight: 600 !important;
  line-height: 21px;
}

.custom-table-header:first-child {
  border-radius: 8px 0px 0px 0px;
}
.custom-table-header:last-child {
  border-radius: 0px 8px 0px 0px;
}
.grid-image {
  width: auto;
  min-width: 6rem;
  height: 6rem;
  object-fit: contain;
  margin: auto;
  padding: 0 0.2rem;
}
.MuiPaper-root {
  outline: none;
}
.MuiPaper-root.MuiCard-root {
  box-shadow: 0px 4px 25px #dcdce5;
  /* border-radius: 24px;  */
}
.MuiFormControlLabel-label {
  /* font-size: 12px !important; */
}
.MuiAutocomplete-input {
  padding: 0px 4px 0px 6px !important;
}
.hexInputs .MuiOutlinedInput-input {
  padding: 2px 4px 2px 6px !important;
}

.whitebg {
  height: 100%;
  width: 100%;
  background: linear-gradient(233deg, #296bd6, #ff71ab);
  opacity: 0.3;
  position: absolute;
}
.dialogGradient {
  background: linear-gradient(233deg, #296bd6, #ff71ab);
  background-size: cover;
  opacity: 0.5;
  background-blend-mode: multiply;
  animation: hue-rotate 3s linear infinite;
  height: 100%;
  width: 100%;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes hue-rotate {
  0% {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0);
    /* background-position: 0% 50%; */
  }
  50% {
    -webkit-filter: hue-rotate(80deg);
    -moz-filter: hue-rotate(80deg);
    -ms-filter: hue-rotate(80deg);
    filter: hue-rotate(80deg);
    /* background-position: 100% 50%; */
  }
  100% {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0);
    /* background-position: 0% 50%; */
  }
}
aside.emoji-picker-react {
  position: absolute !important;
  top: 5rem;
  right: 2rem;
  z-index: 100;
}
.polotno-side-panel-tab svg {
  margin: auto;
}
.bp5-portal {
  z-index: 1301 !important;
}
.videoBuffering {
  animation: buffering 1s infinite both;
}

@keyframes buffering {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.videoTrim {
  height: 500px;
  width: 100%;
}
.cmnVideos {
  max-height: 20rem;
}
img,
video {
  max-width: 100%;
  /* height: auto; */
}
.cs-message-input {
  white-space: pre-wrap;
  margin-left: 5rem;
  margin-right: 5rem;
}
@media (max-width: 600px) {
  .cs-message-input {
    margin-left: 4rem;
    margin-right: 1rem;
  }
}
.cs-typing-indicator {
  padding-left: 2rem !important;
}
.cs-message:not(.cs-message--outgoing) .cs-message__content {
  background-color: rgba(41, 107, 214, 0.08) !important;
}
.cs-message--outgoing .cs-message__content {
  border-color: #e2e2e2;
  border: 0.5px solid;
  background-color: inherit !important;
}

@media (min-width: 640px) {
  .brand-color-circle .close {
    display: none;
  }
  .brand-color-circle:hover .close {
    display: block;
  }
}
.cs-input-toolbox {
  position: absolute;
  left: 4%;
  bottom: 5px;
}

.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px dashed #999999;
  outline: none;
}

.inline-text_copy--active {
  cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border-bottom: 1px solid #666666;
  text-align: left;
}
.app-annoucement-wrapper {
  width: 450px !important;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0 8px 15px -5px #7c7c7c;
  padding: 1rem;
  position: fixed;
  background: #fff;
  left: 5rem;
  bottom: 5px;
  z-index: 9;
  flex-direction: column;
  text-decoration: none !important;
  color: #282c34 !important;
}

@media (max-width: 500px) {
  .app-annoucement-wrapper {
    width: 80% !important;
    left: 1%;
    bottom: 5rem;
  }
}

.badge-success {
  color: #ffffff;
  background-color: #00bf9a;
}

.badge {
  text-transform: uppercase;
  line-height: 12px;
  border: none;
  text-decoration: none;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.68187rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.script-textAreaWrapper {
  border-radius: 2px;
  width: 100%;
  height: 220px;
  display: flex;
}
.script-sharedStyle {
  margin: 0;
  padding: 10px 0;
  height: 200px;
  border-radius: 0;
  resize: none;
  outline: none;
  font-size: 15px;
  line-height: 1.2;
  :focus-visible {
    outline: none;
  }
}
.script-textArea {
  padding-left: 0.5rem;
  width: 100%;
  border: none;
  font-family: "Hanken Grotesk", sans-serif !important;
  ::placeholder {
    color: grey;
  }
}

.script-numbers {
  /* display: flex; */
  /* flex-direction: column; */
  overflow-y: hidden;
  text-align: right;
  box-shadow: none;
  /* position: absolute; */
  color: grey;
  border: none;
  padding: 10px;
  background-color: white;
  min-width: 5.5rem;
}

/* temporary fix to override blueprint.css outline */
:focus {
  outline: none !important;
  outline-offset: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar:horizontal {
  height: 7px;
}

::-webkit-scrollbar:horizontal {
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #747189;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #747189;
}
